.final-order-table {
  width: 100%;
}

.final-order-table__header {
  text-align: left;
}

.final-order-table__header-cell {
  border-bottom: 2px solid black;
}

.final-order-table__footer-cell {
  border-bottom: 1px solid var(--theme-ui-colors-primaryGrayLight, #C4C4C4);
}

.final-order-table__cell--centered {
  text-align: center;
}

.final-order-table__product-name {
  text-wrap: balance;
  white-space: pre-line;
  font-family: Roboto;
  font-size: 14px;
}

th, td {
  padding: 4px;
}

.final-order-table__footer-label {
  padding-top: 16px;
}