.image-gallery {
  width: 100%;
  height: auto;
}

.image-gallery-slide img {
  width: 100% !important;
  height: 320px;
  object-fit: scale-down !important;
  overflow: hidden;
  object-position: center center;
}

.image-gallery-thumbnail-image {
  height: 65px;
  object-fit: scale-down;
  line-height: 0;
}