.popper__arrow,
.popper__arrow::before {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 2;
}

.popper__arrow::before {
  content: '';
  transform: rotate(45deg);
  background: #fff;
  border: 1px solid #FF671B;
}

.popover-container {
  animation: fade-in .5s;
}

.popover-container[data-popper-placement^='top'] > .popper__arrow {
  bottom: -4px;
}

.popover-container[data-popper-placement^='top-start'] > .popper__arrow {
  bottom: -4px;
  left: -120px !important;
}

.popover-container[data-popper-placement^='top-end'] > .popper__arrow {
  bottom: -4px;
  left: 330px !important;
}

.popover-container[data-popper-placement^='top'] > .popper__arrow::before {
  border-left: 0;
  border-top: 0;
}

.popover-container[data-popper-placement^='bottom'] > .popper__arrow {
  top: -6px;
}

.popover-container[data-popper-placement^='bottom'] > .popper__arrow::before {
  border-right: 0;
  border-bottom: 0;
}

.popover-container[data-popper-placement^='left'] > .popper__arrow {
  right: -4px;
}

.popover-container[data-popper-placement^='left'] > .popper__arrow::before {
  border-bottom: 0;
  border-left: 0;
}

.popover-container[data-popper-placement^='right'] > .popper__arrow {
  left: -4px;
}

.popover-container[data-popper-placement^='right'] > .popper__arrow::before {
  border-top: 0;
  border-right: 0;
  left: -2px;
}

.video-tip-popover-arrow {
  position: absolute;
  transform: rotate(45deg);
  background: #fff;
  border: 1px solid #FF671B;
  height: 25px;
  width: 25px;
  border-bottom: 0;
  border-right: 0;
  top: -13.5px;
  left: 39px;
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}