.Toastify__toast-container {
  max-width: 800px;
  width: 100%;
}

.Toastify__toast {
  border: solid 2px #FF671B;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  min-height: 50px;
  padding: 0;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 10px;
    left: 0;
    margin: 0;
  }
  
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  display: none;
}
.Toastify__close-button--default {
  display: none;
}
.Toastify__close-button > svg {
  display: none;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  display: none;
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
  background: #fff;
}
.Toastify__progress-bar--animated {
  background: #fff;
}
.Toastify__progress-bar--controlled {
  background: #fff;
}
.Toastify__progress-bar--rtl {
  background: #fff;
}
.Toastify__progress-bar--default {
  background: #fff;
}
.Toastify__progress-bar--dark {
  background: #fff;
}