#okta-sign-in {
  margin-top: 0;
}

#okta-sign-in.auth-container.main-container {
  font-family: 'Lato',Arial,Helvetica,sans-serif;
}

#okta-sign-in.auth-container input[type=button], #okta-sign-in.auth-container input[type=submit] {
  font-family: 'Lato',Arial,Helvetica,sans-serif;
}

#okta-sign-in.auth-container .okta-form-input-field input {
  font-family: 'Lato',Arial,Helvetica,sans-serif;
}

#okta-sign-in.auth-container .okta-form-input-field.focused-input {
  border-color: #FF671B;
}

#okta-sign-in .focused-input {
  box-shadow: none;
}

#okta-sign-in .auth-divider, #okta-sign-in .js-help, #okta-sign-in .link.js-help-link {
  display: none;
}

#okta-sign-in .help-links {
  display: block !important;
}

#okta-sign-in .o-form-button-bar  {
  padding: 0;
}

#okta-sign-in .default-custom-button.link-button {
  background-color: #fff;
  border-color: #FF671B;
  color: #FF671B;
  height: 36px;
  line-height: 36px;
  border: none;
}

#okta-sign-in .default-custom-button.link-button:hover {
  background-color: #fff;
}

#okta-sign-in.auth-container .button.button-primary.link-button-disabled {
  color: #fff;
  background-color: #C4C4C4;
  border-color: #C4C4C4;
  background-image: none;
  box-shadow: none;
}

#okta-sign-in.auth-container .button-primary {
  background: #FF671B;
  height: 36px;
  line-height: 36px;
  border: none;
}

#okta-sign-in .beacon-loading:after {
  border-top-color: #FF671B;
}

#okta-sign-in.auth-container .link-button-disabled.disabled {
  background: #ff671B;
}

#okta-sign-in.auth-container .button-primary:active, #okta-sign-in.auth-container .button-primary:focus, #okta-sign-in.auth-container .button-primary:hover {
  background: #FF671B;
}

.button.button-primary.link-button-disabled {
  /* -- Buttons' Colors -- */
  color: 'white';
  background-color: #C4C4C4;
  border-color: #C4C4C4;
  background-image: none;
  box-shadow: none;
}