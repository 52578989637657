.custom-date-field {
  flex: 1;
  border: 1px solid;
  height: 28px;
  border-radius: 4px;
  border-color: #C4C4C4;
  font-family: 'Roboto';
  padding: 8px;
  font-size: inherit;
  line-height: inherit;
  margin: 8px 0px;
}
