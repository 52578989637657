.WeekSelector {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  cursor: pointer;
}

.WeekSelector input {
  cursor: pointer;

}

.WeekSelector .DayPicker-Month {
  border-collapse: separate;
}

.WeekSelector .DayPicker-WeekNumber {
  outline: none;
}

.WeekSelector .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
  border-radius: 0 !important;
}

.WeekSelector .DayPicker-Day--hoverRange:not(.DayPicker-Day--disabled) {
  background-color: #FF6000 !important;
  cursor: pointer;
  color: white !important;
  border-radius: 0 !important;
}

.WeekSelector .DayPicker-Day--selectedRange {
  border-radius: 0 !important;
  color: white !important;
  background-color: #FF6000 !important;
}

.WeekSelector .DayPicker-Day--selectedRangeStart {
  color: white !important;
}

.WeekSelector .DayPicker-Day--selectedRangeEnd {
  color: white;
}

.WeekSelector .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.WeekSelector .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  color: white !important;
  background-color: #FF6000;
}
.WeekSelector .DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important; 
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
  background-color: #FF6000 !important;
  color: white !important;
}

.DayPicker-Day--today {
  color: #FF6000 !important;
  font-weight: 900 !important;
  border: 1px solid #FF6000 !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: red;
  }